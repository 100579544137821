import React from 'react'
import { PrimaryButton } from '../../Button/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { releaseListing, reserveListing } from '../../../containers/ListingPage/ListingPage.duck'
import { useState } from 'react'
import { useEffect } from 'react'
import { postGetAllReserveListing } from '../../../util/api'
import css from './ReserveListingButton.module.css'
import Modal from '../../Modal/Modal'
import ReservationHistory from './ReservationHistory'

const ReserveListingButton = props => {
    const dispatch = useDispatch()
    const {
        disabled,
        listing,
        onManageDisableScrolling,
    } = props
    const listingId = listing.id.uuid
    const { isReserved, reservationHistoryCount } = listing?.attributes.publicData || {}
    const [isLoading, setIsLoading] = useState(false)
    const [reservationHistory, setReservationHistory] = useState([])
    const [reservationHistoryCountDisplay, setReservationHistoryCountDisplay] = useState(0)
    const [showReservationHistory, setShowReservationHistory] = useState(false)

    useEffect(() => {
        if (reservationHistoryCount) {
            setReservationHistoryCountDisplay(reservationHistoryCount)
        }
    }, [reservationHistoryCount])


    const onOpenReservationHistory = async () => {
        setIsLoading(true)
        setShowReservationHistory(true)
        try {
            const response = await postGetAllReserveListing({ listingId })
            setReservationHistory(response)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
        }
    }

    const onClickAction = async () => {
        setIsLoading(true)
        const subUserId = window.localStorage.getItem("subUserId")
        const params = { listingId, subUserId }
        try {
            if (isReserved) {
                await dispatch(releaseListing(params))
            } else {
                await dispatch(reserveListing(params))
            }
        } catch (err) {
            setIsLoading(false)
        }
    }

    return <div className={css.reservationSectionWrapper}>
        <p className={css.reservationHistoryWrapper}>
            <a
                onClick={onOpenReservationHistory}
            >
                <FormattedMessage id={`ReserveListingButton.reservationHistory`} values={{ reservationHistoryCountDisplay }} />
            </a>
        </p>
        <Modal
            id="MissingInformationReminder"
            isOpen={showReservationHistory}
            onClose={() => setShowReservationHistory(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
        >
            <div className={css.reservationModalWrapper}>
                <ReservationHistory
                isLoading={isLoading}
                reservationHistory={reservationHistory}
                />
            </div>
        </Modal>
        <PrimaryButton
            onClick={onClickAction}
            disabled={disabled}
            inProgress={isLoading}
        >
            <FormattedMessage id={`ReserveListingButton.ctaButtonMessage${isReserved ? "release" : "reserve"}`} />
        </PrimaryButton>
    </div>
}

export default ReserveListingButton