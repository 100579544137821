import { deleteListing, hideListingEndpoint } from '../../util/api';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const ADD_WAREHOUSE = 'app/ManageWarehousePage/ADD_WAREHOUSE';
export const ADD_WAREHOUSE_LOADING = 'app/ManageWarehousePage/ADD_WAREHOUSE_LOADING';
export const ADD_WAREHOUSE_ERROR = 'app/ManageWarehousePage/ADD_WAREHOUSE_ERROR';

// ================ Reducer ================ //

const initialState = {
  addWarehouseLoading: false,
  addWarehouseError: null,
};

const manageWarehousePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_WAREHOUSE:
      // const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, addWarehouseLoading: false };
    case ADD_WAREHOUSE_LOADING:
      return { ...state, addWarehouseLoading: true };
    case ADD_WAREHOUSE_ERROR:
      return { ...state, addWarehouseLoading: false, addWarehouseError: payload };

    default:
      return state;
  }
};

export default manageWarehousePageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const addWarehouseSuccess = transaction => ({
  type: ADD_WAREHOUSE,
  payload: transaction.data,
});

export const addWarehouseError = error => ({
  type: ADD_WAREHOUSE_ERROR,
  error: true,
  payload: error,
});

export const addWareHouse = listing => async (dispatch, getState, sdk) => {
  dispatch({ type: ADD_WAREHOUSE_LOADING });

  try {
    await sdk.currentUser
      .updateProfile({ publicData: listing }, { expand: true })
      .then(response => {
        dispatch(addWarehouseSuccess(response.data));
        window.location.reload();
      })
      .catch(e => {
        dispatch(addWarehouseError(storableError(e)));
      });
  } catch (e) {
    dispatch(addWarehouseError(storableError(e)));
  }
};
