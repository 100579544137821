import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import css from './ReserveListingButton.module.css'

const ReservationHistory = props => {
    const { currentUser } = useSelector(state => state.user)
    const { reservationHistory, isLoading } = props


    return isLoading
        ? <p>Laddar...</p>
        : reservationHistory.length > 0
            ? <div>{reservationHistory.map(({ _id, action, createdAt, isMainAccount, subUserId }) => {
                let firstName = null
                let lastName = null
                if (subUserId) {
                    firstName = subUserId.firstName
                    lastName = subUserId.lastName
                } else if (isMainAccount) {
                    firstName = currentUser.attributes.profile.firstName
                    lastName = currentUser.attributes.profile.lastName
                } else {
                    firstName = "Deleted"
                    lastName = "User"
                }

                const actionMapper = {
                    "delete": "Öppnad",
                    "create": "Reserverad"
                }
                return <div key={_id} className={css.entryWrapper}>
                    <span>{actionMapper[action]} av {firstName} {lastName} </span>
                    <span className={css.timestamp}>{new Date(createdAt).toLocaleDateString()} {new Date(createdAt).toLocaleTimeString()}</span>
                </div>
            })}</div>
            : <p><FormattedMessage id={`ReserveListingButton.noEvents`} /></p>
}

export default ReservationHistory