// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { fetchCurrentUser } from '../../ducks/user.duck';
// import { types as sdkTypes } from '../../util/sdkLoader';

import {
    getConnectedUserStatus,
    createConnectedUser,
    getSubUsers,
    addSubUser,
    deleteSubUser,
} from '../../util/api';

// const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/SubUsersPage/SET_INITIAL_STATE';

export const GET_CONNECTED_SAVED_USER_REQUEST = 'app/SubUsersPage/GET_CONNECTED_SAVED_USER_REQUEST';
export const GET_CONNECTED_SAVED_USER_SUCCESS = 'app/SubUsersPage/GET_CONNECTED_SAVED_USER_SUCCESS';
export const GET_CONNECTED_SAVED_USER_ERROR = 'app/SubUsersPage/GET_CONNECTED_SAVED_USER_ERROR';

export const CREATE_CONNECTED_USER_REQUEST = 'app/SubUsersPage/CREATE_CONNECTED_USER_REQUEST';
export const CREATE_CONNECTED_USER_SUCCESS = 'app/SubUsersPage/CREATE_CONNECTED_USER_SUCCESS';
export const CREATE_CONNECTED_USER_ERROR = 'app/SubUsersPage/CREATE_CONNECTED_USER_ERROR';

export const GET_SUBUSERS_REQUEST = 'app/SubUsersPage/GET_SUBUSERS_REQUEST';
export const GET_SUBUSERS_SUCCESS = 'app/SubUsersPage/GET_SUBUSERS_SUCCESS';
export const GET_SUBUSERS_ERROR = 'app/SubUsersPage/GET_SUBUSERS_ERROR';

export const CREATE_SUBUSER_REQUEST = 'app/SubUsersPage/CREATE_SUBUSER_REQUEST';
export const CREATE_SUBUSER_SUCCESS = 'app/SubUsersPage/CREATE_SUBUSER_SUCCESS';
export const CREATE_SUBUSER_ERROR = 'app/SubUsersPage/CREATE_SUBUSER_ERROR';

export const DELETE_SUBUSER_REQUEST = 'app/SubUsersPage/DELETE_SUBUSER_REQUEST';
export const DELETE_SUBUSER_SUCCESS = 'app/SubUsersPage/DELETE_SUBUSER_SUCCESS';
export const DELETE_SUBUSER_ERROR = 'app/SubUsersPage/DELETE_SUBUSER_ERROR';

// ================ Reducer ================ //

const initialState = {
    getUserInProgress: false,
    getUserError: null,
    userIsLinked: false,
    createUserInProgress: false,
    createUserError: null,
    subUsers: [],
    getSubUsersInProgress: false,
    getSubUsersError: null,
    createSubUserInProgress: false,
    createSubUserError: null,
    subUserBeingDeleted: null,
};

export default function subUsersPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_STATE:
            return { ...initialState };
        case GET_CONNECTED_SAVED_USER_REQUEST:
            return { ...state, getUserError: null, getUserInProgress: true };
        case GET_CONNECTED_SAVED_USER_SUCCESS:
            return { ...state, getUserInProgress: false, userIsLinked: payload };
        case GET_CONNECTED_SAVED_USER_ERROR:
            return { ...state, getUserError: payload, getUserInProgress: false };

        case CREATE_CONNECTED_USER_REQUEST:
            return { ...state, createUserError: null, createUserInProgress: true };
        case CREATE_CONNECTED_USER_SUCCESS:
            return { ...state, createUserInProgress: false, userIsLinked: true };
        case CREATE_CONNECTED_USER_ERROR:
            return { ...state, createUserError: payload, createUserInProgress: false };

        case GET_SUBUSERS_REQUEST:
            return { ...state, getSubUsersError: null, getSubUsersInProgress: true };
        case GET_SUBUSERS_SUCCESS:
            return { ...state, getSubUsersInProgress: false, subUsers: payload };
        case GET_SUBUSERS_ERROR:
            return { ...state, getSubUsersError: payload, getSubUsersInProgress: false };

        case CREATE_SUBUSER_REQUEST:
            return { ...state, createSubUserError: null, createSubUserInProgress: true };
        case CREATE_SUBUSER_SUCCESS:
            return { ...state, createSubUserInProgress: false };
        case CREATE_SUBUSER_ERROR:
            return { ...state, createSubUserError: payload, createSubUserInProgress: false };

        case DELETE_SUBUSER_REQUEST:
            return { ...state, subUserBeingDeleted: payload };
        case DELETE_SUBUSER_SUCCESS:
            return { ...state, subUserBeingDeleted: null };
        case DELETE_SUBUSER_ERROR:
            return { ...state, subUserBeingDeleted: null };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
});

export const getUserRequest = () => ({
    type: GET_CONNECTED_SAVED_USER_REQUEST,
});

export const getUserSuccess = (userIsLinked) => ({
    type: GET_CONNECTED_SAVED_USER_SUCCESS,
    payload: userIsLinked,
});

export const getUserError = e => ({
    type: GET_CONNECTED_SAVED_USER_ERROR,
    error: true,
    payload: e,
});


export const createUserRequest = () => ({
    type: CREATE_CONNECTED_USER_REQUEST,
});

export const createUserSuccess = (userIsLinked) => ({
    type: CREATE_CONNECTED_USER_SUCCESS,
    payload: userIsLinked,
});

export const createUserError = e => ({
    type: CREATE_CONNECTED_USER_ERROR,
    error: true,
    payload: e,
});


export const getSubUsersRequest = () => ({
    type: GET_SUBUSERS_REQUEST,
});

export const getSubUsersSuccess = (subUsers) => ({
    type: GET_SUBUSERS_SUCCESS,
    payload: subUsers,
});

export const getSubUsersError = e => ({
    type: GET_SUBUSERS_ERROR,
    error: true,
    payload: e,
});


export const createSubUserRequest = () => ({
    type: CREATE_SUBUSER_REQUEST,
});

export const createSubUserSuccess = () => ({
    type: CREATE_SUBUSER_SUCCESS,
});

export const createSubUserError = e => ({
    type: CREATE_SUBUSER_ERROR,
    error: true,
    payload: e,
});

export const deleteSubUserRequest = (id) => ({
    type: DELETE_SUBUSER_REQUEST,
    payload: id,
});

export const deleteSubUserSuccess = () => ({
    type: DELETE_SUBUSER_SUCCESS,
});

export const deleteSubUserError = () => ({
    type: DELETE_SUBUSER_ERROR,
});

// ================ Thunks ================ //

export const getConnectedSavedUser = () => (dispatch, getState, sdk) => {
    dispatch(getUserRequest());

    return getConnectedUserStatus({})
        .then(response => {
            dispatch(getUserSuccess(response.userFound));
            dispatch(querySubUsers());
        })
        .catch(e => dispatch(getUserError(e)));
};

export const createConnectedSavedUser = (params) => (dispatch, getState, sdk) => {
    dispatch(createUserRequest());

    return sdk.login(params)
        .then(() => {
            return createConnectedUser({ password: params.password })
                .then(() => {
                    dispatch(createUserSuccess());
                })
                .catch(() => dispatch(createUserError("Something went wrong")));
        })
        .catch(() => {
            dispatch(createUserError("Invalid Password"));
        });
};

export const querySubUsers = () => (dispatch, getState, sdk) => {
    dispatch(getSubUsersRequest());

    return getSubUsers({})
        .then((res) => {
            dispatch(getSubUsersSuccess(res.subUsers));
            return res;
        })
        .catch(() => dispatch(getSubUsersError("Something went wrong")));
};

export const createSubUser = (params) => (dispatch, getState, sdk) => {
    dispatch(createSubUserRequest());

    return addSubUser(params)
        .then((res) => {
            if (res.userCreated) {
                dispatch(createSubUserSuccess());
                dispatch(querySubUsers());
                return res;
            } else {
                dispatch(createSubUserError(res.error));
            };
        })
        .catch(() => dispatch(createSubUserError("Something went wrong")));
};

export const removeSubUser = (params) => (dispatch, getState, sdk) => {
    dispatch(deleteSubUserRequest(params._id));

    return deleteSubUser(params)
        .then((res) => {
            dispatch(deleteSubUserSuccess());
            dispatch(querySubUsers());
            return res;
        })
        .catch(() => dispatch(deleteSubUserError()));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    dispatch(setInitialState());

    return Promise.all([
        dispatch(getConnectedSavedUser()),
    ]);
};
