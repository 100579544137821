import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { isFieldForCategory, isFieldForListingType } from '../../util/fieldHelpers';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  images: {
    marginVertical: 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 24,
  },
  description: {
    fontWeight: 'normal',
    fontSize: 16,
    marginTop: 8,
  },
  addMargin: {
    marginTop: 8,
  },
  addBold: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 16,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    borderBottom: '1px solid grey',
  },
  flexTitle: {
    fontWeight: 'bold',
  },
  item: {
    fontSize: 16,
  },
});

// Create Document Component
const PDFDocument = props => {
  const { listing, price, categories, listingFieldConfigs, intl, metadata } = props;
  const { attributes, author, images } = listing || {};
  const { title, description, publicData } = attributes || {};
  const { categoryLevel1, categoryLevel2 } = publicData || {};
  const { displayName } = author?.attributes?.profile || {};

  const selectedCategory = categories?.find(category => category.id == categoryLevel1) || {};
  const selectedSubCategory =
    selectedCategory?.subcategories?.find(subcategory => subcategory.id == categoryLevel2) || {};
  const categoryDisplay = selectedCategory?.name || '';
  const subcategoryDisplay = selectedSubCategory?.name || '';

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          {images
            ? images.map(img => {
                return (
                  <Image
                    key={img.id}
                    src={img.attributes.variants['facebook'].url}
                    style={styles.images}
                  />
                );
              })
            : null}
        </View>

        <View>
        <Text style={styles.title}>{title}</Text>
          <Text style={styles.addBold}>{price}</Text>
          <Text style={styles.addBold}>Beskrivning</Text>
          <Text style={styles.description}>{description}</Text>
          <Text style={styles.addBold}>Bilmodell</Text>
          <Text style={styles.description}>
            {categoryDisplay} {subcategoryDisplay}
          </Text>
          <Text style={styles.addBold}>Detaljer</Text>
          <View style={styles.addMargin}>
            {existingListingFields.map(field => (
              <View style={styles.row} key={field.key}>
                <Text style={styles.flexTitle}>{field.label}</Text>
                <Text style={styles.item}>{field.value}</Text>
              </View>
            ))}
          </View>
          <Text style={styles.addBold}>Owner</Text>
          <Text style={styles.description}>{displayName}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
